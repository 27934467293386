import React from "react"
import { Grid, Typography, Container, Box, Button } from "@mui/material"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

import { Center, FancyHr } from "../Layout"
import { AnimateOnScroll } from "../AnimateOnScroll"

import MissionHero from "./MissionHero"
import { Segment } from "../Segment"

const Mission = () => {
  return (
    <>
      <MissionHero />
      <Segment>
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  color="primary"
                  align="center"
                  gutterBottom
                >
                  The Vision
                </Typography>
                <FancyHr />
                <Box mb={4}>
                  <Typography align="center">
                    Our mission and vision embraces the importance of higher
                    learning, moral character and commitment to the common good
                    through financial leadership and support for the ELB
                    Scholars Program (ELBSP). As a result of this leadership and
                    vision a greater number of young men from Palm Beach County,
                    FL high schools will continue to receive scholarships to
                    attend Historically Black Colleges or Universities while
                    becoming scholastic leaders locally and globally.
                  </Typography>
                </Box>
                <Box mb={4}>
                  <Typography align="center">
                    The criteria to participate in the Scholars Program includes
                    a student’s commitment to pursue a higher education, his
                    participation in one or more National Urban League youth
                    programs and a minimum G.P.A. of 2.5. Students accepted in
                    ELBSP are expected to complete a challenging project of
                    their choice under the guidance of members of the ELB
                    Foundation.
                  </Typography>
                </Box>
                <Box mb={4}>
                  <Typography align="center">
                    We believe that our financial leadership and support will
                    accelerate and expand ELB Scholars Program ability to make a
                    profound impact on the lives of promising young men.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Center>
                  <StaticImage
                    src="../../images/epsilon-logo-boule-scholars.jpg"
                    alt="elb logo"
                    placeholder="blurred"
                    width={550}
                  />
                </Center>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Center>
                  <Button
                    component={Link}
                    to="/boule-scholars"
                    variant="contained"
                    color="alternate"
                  >
                    Boule Scholars Program
                  </Button>
                </Center>
              </Grid>
            </Grid>
          </Container>
        </AnimateOnScroll>
      </Segment>
      <Segment variant="primary">
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" color="common.white" align="center">
                  The Future
                </Typography>
                <FancyHr alt />
                <Typography color="common.white" align="center">
                  Through our efforts, we hope to provide over $1Million dollars
                  in scholarships to young men from Palm Beach County striving
                  for academic excellence through undergraduate and graduate
                  programs that are affiliated with HBCUs. We are convinced that
                  many of these young men will become valuable leaders in our
                  community and will contribute their talents to the greater
                  good of our nation.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </AnimateOnScroll>
      </Segment>
    </>
  )
}
export default Mission
