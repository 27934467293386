import React from "react"
import { Grid, Typography, Container, Box, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import { AnimateOnScroll } from "../AnimateOnScroll"
import { Title } from "../Title"

const MissionBgImage = ({ ...props }) => {
  const theme = useTheme()

  const isSmallandMediumPortrait = useMediaQuery(
    theme.breakpoints.between("xs", "lg")
  )
  const bgDimensions = { height: 400, width: "100%" }
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "images/hero-mission.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const image = getImage(placeholderImage)

  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage
      Tag="section"
      {...bgImage}
      preserveStackingContext
      {...props}
    >
      <Box
        sx={{ minHeight: bgDimensions.height, minWidth: bgDimensions.width }}
      >
        <Container disableGutters>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: bgDimensions.height,
                }}
              >
                <Box sx={{ px: isSmallandMediumPortrait ? 2 : 0 }}>
                  <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
                    <Title variant="hero" align="center">
                      The Mission
                    </Title>

                    <Typography variant="h4" align={`center`}>
                      Character & Commitment Through Financial Leadership
                    </Typography>
                  </AnimateOnScroll>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </BackgroundImage>
  )
}
export default MissionBgImage
